<!--
 * @Description: 
 * @Author: 爱酱丶
 * @Date: 2022-11-08 14:56:53
 * @LastEditTime: 2022-12-04 16:19:23
 * @LastEditors: 爱酱丶
-->
<template>
  <div class="register-page">
    <div class="box">
      <div class="text">
        {{ $t('pcregister').p1 }}
        <router-link to="login" class="text-register">{{
          $t('pcregister').dl
        }}</router-link>
      </div>
      <div class="register-box">
        <div class="title">{{ $t('pcregister').zc }}</div>
        <!-- switchTab -->
        <el-tabs v-model="RegeistType" @tab-click="switchTabHandleClick" lazy>
          <el-tab-pane :label="$t('pcregister').p4" name="Account">
            <!-- form表单 -->
            <el-form
              ref="accountForm"
              :model="accountForm"
              :rules="accountFormRules"
            >
              <el-form-item prop="UserName">
                <el-input
                  v-model="accountForm.UserName"
                  :placeholder="$t('pcregister').p5"
                ></el-input>
              </el-form-item>
              <el-form-item prop="Password">
                <el-input
                  v-model="accountForm.Password"
                  type="password"
                  :placeholder="$t('pcregister').p6"
                ></el-input>
              </el-form-item>
              <el-form-item prop="subPassword">
                <el-input
                  v-model="accountForm.subPassword"
                  type="password"
                  :placeholder="$t('pcregister').p7"
                ></el-input>
              </el-form-item>
              <el-form-item prop="InvitationCode" class="last">
                <el-input
                  v-model="accountForm.InvitationCode"
                  :placeholder="$t('pcregister').p8"
                ></el-input>
              </el-form-item>
              <!-- 单选框 -->
              <div class="agree">
                <el-checkbox v-model="checked">{{
                  $t('pcregister').p9
                }}</el-checkbox>
                <span @click="goXY">{{ $t('pcregister').p10 }}</span>
              </div>
              <el-button type="primary" @click="handleSub">{{
                $t('pcregister').dl
              }}</el-button>
            </el-form>
          </el-tab-pane>
          <el-tab-pane :label="$t('pcregister').p11" name="Mailbox">
            <el-form
              :model="accountForm"
              :rules="emailFormRules"
              ref="emailForm"
            >
              <el-form-item prop="UserName">
                <el-input
                  v-model="accountForm.UserName"
                  :placeholder="$t('pcregister').p12"
                ></el-input>
              </el-form-item>
              <el-form-item prop="EmailCode" class="btn-wrap">
                <el-input
                  v-model="accountForm.EmailCode"
                  :placeholder="$t('pcregister').p13"
                ></el-input>
                <el-button
                  @click="handleClick"
                  :disabled="countDownSec !== 60"
                  >{{
                    countDownSec === 60 ? $t('pcregister').p14 : countDownSec
                  }}</el-button
                >
              </el-form-item>
              <el-form-item prop="Password">
                <el-input
                  v-model="accountForm.Password"
                  type="password"
                  :placeholder="$t('pcregister').p6"
                ></el-input>
              </el-form-item>
              <el-form-item prop="subPassword">
                <el-input
                  v-model="accountForm.subPassword"
                  type="password"
                  :placeholder="$t('pcregister').p7"
                ></el-input>
              </el-form-item>
              <el-form-item prop="InvitationCode" class="last">
                <el-input
                  v-model="accountForm.InvitationCode"
                  :placeholder="$t('pcregister').p8"
                ></el-input>
              </el-form-item>
              <!-- 单选框 -->
              <div class="agree">
                <el-checkbox v-model="checked">{{
                  $t('pcregister').p9
                }}</el-checkbox>
                <span @click="goXY">{{ $t('pcregister').p10 }}</span>
              </div>
              <el-button type="primary" @click="handleSub"
                >{{ $t('pcregister').zc }}
              </el-button>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import { getUrlParam } from '@/utils/validate'
import { EventBus } from '@/utils/event-bus'
export default {
  data() {
    // 检验两次密码是否一样
    const checkPwdTheSame = (rule, value, callback) => {
      if (value !== this.accountForm.Password) {
        callback(new Error(this.$t('register').message2))
      }

      callback()
    }
    const checkEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('register').placeholder))
      }
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      if (!reg.test(value.toLowerCase())) {
        callback(new Error(this.$t('register').placeholder5))
      } else {
        callback()
      }
    }
    return {
      RegeistType: 'Account',
      accountForm: {
        subPassword: '',
        Password: '',
        UserName: '',
        InvitationCode: '',
        EmailCode: '',
        RegeistType: 'Mailbox'
      },
      accountFormRules: {
        // 用户名校验
        UserName: [
          {
            required: true,
            message: this.$t('register').placeholder0,
            position: 'bottom',
            trigger: 'blur'
          }
        ],
        // 密码校验
        Password: [
          { min: 6, message: this.$t('register').message, trigger: 'blur' },
          {
            required: true,
            message: this.$t('register').message,
            trigger: 'blur'
          }
        ],
        subPassword: [
          {
            required: true,
            message: this.$t('register').message,
            trigger: 'blur'
          },
          {
            min: 6,
            message: this.$t('register').placeholder0,
            trigger: 'blur'
          },
          {
            validator: checkPwdTheSame,
            message: this.$t('register').message2,
            position: 'bottom',
            trigger: 'blur'
          }
        ],
        EmailCode: [
          {
            required: true,
            trigger: 'blur',
            message: '验证按不能为空'
          }
        ]
      },
      emailFormRules: {
        // 用户名校验
        UserName: [
          {
            required: true,
            message: this.$t('register').placeholder0,
            position: 'bottom',
            trigger: 'blur'
          },
          {
            validator: checkEmail,
            message: this.$t('register').message2,
            trigger: 'blur'
          }
        ],
        // 密码校验
        Password: [
          { min: 6, message: this.$t('register').message, trigger: 'blur' },
          {
            required: true,
            message: this.$t('register').message,
            trigger: 'blur'
          }
        ],
        subPassword: [
          {
            required: true,
            message: this.$t('register').message,
            trigger: 'blur'
          },
          {
            min: 6,
            message: this.$t('register').placeholder0,
            trigger: 'blur'
          },
          {
            validator: checkPwdTheSame,
            message: this.$t('register').message2,
            trigger: 'blur'
          }
        ],
        EmailCode: [
          {
            required: true,
            trigger: 'blur',
            message: '验证码不能为空'
          }
        ]
      },
      checked: true,
      countDownSec: 60 // 验证码倒计时
    }
  },
  mounted() {
    this.InvitationCode = getUrlParam('InvitationCode')
    this.RegeistType = this.basicConfig.RegisterSelect[0]
    this.RegisterSelect = this.basicConfig.RegisterSelect.join(',')
  },
  methods: {
    // tab 切换
    switchTabHandleClick(tab) {
      this.RegeistType = tab.name
      this.$refs.accountForm.clearValidate()
      this.$refs.emailForm.clearValidate()
      this.$refs.accountForm.resetFields()
      this.$refs.emailForm.resetFields()
    },
    // 阅读用户协议
    goXY() {
      let info = ''
      if (this.lang === 'zh') {
        info = this.basicConfig.Content
      } else if (this.lang === 'ty') {
        info = this.basicConfig.ContentThai
      } else if (this.lang === 'jp') {
        info = this.basicConfig.ContentJapanese
      } else if (this.lang === 'en') {
        info = this.basicConfig.ContentEnglish
      } else if (this.lang === 'yn') {
        info = this.basicConfig.ContentIndonesia
      } else if (this.lang === 'hy') {
        info = this.basicConfig.ContentKorea
      }
      this.$router.push({
        name: 'agreement',
        query: {
          title: this.$t('register').xy,
          info
        }
      })
    },
    // 登录
    handleLogin() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          if (!this.checked) {
            this.$toast({
              message: this.$t('register').message3,
              position: 'bottom'
            })
          }
          // 获取refreshToken
          let res = await userApi.getRefreshToken({
            username: this.accountForm.UserName,
            password: this.accountForm.Password
          })
          // 获取token
          let res1 = await userApi.login(res.Token)
          Token.set(res1.Token)
          EventBus.$emit('Login')
          setTimeout(() => {
            this.$router.push({ name: 'Home' })
          }, 1000)
        }
      })
    },
    // 注册逻辑
    handleSub() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          // 校验通过
          if (!this.checked) {
            // 勾选协议
            this.$toast({
              message: this.$t('register').message3,
              position: 'bottom'
            })
          }
          const form = {
            UserName: this.accountForm.UserName,
            EmailCode: this.accountForm.EmailCode,
            RegeistType: this.RegeistType,
            Password: this.accountForm.Password,
            InvitationCode: this.accountForm.InvitationCode
          }
          await userApi.register(form)
          this.$toast(this.$t('register').success)
          setTimeout(() => {
            this.handleLogin()
          }, 1000)
        }
      })
    },
    // 发送验证码
    async handleClick() {
      if (this.timer || !this.checkEmail1()) return
      await userApi.sendEmail({ email: this.accountForm.UserName })
      this.timer = setInterval(() => {
        this.countDownSec--
        if (this.countDownSec === 0) {
          this.countDownSec = 60
          this.tips = this.$t('register').Resend
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    checkEmail1() {
      if (this.UserName === '') {
        this.$toast({
          message: this.$t('register').placeholder,
          position: 'bottom'
        })
        return false
      }
      const reg = new RegExp(
        '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
      )
      if (!reg.test(this.accountForm.UserName.toLowerCase())) {
        this.$toast(this.$t('register').placeholder5)
        return false
      } else {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang'])
  }
}
</script>

<style lang="less" scoped>
.register-page {
  background-color: #f8f8f8;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: auto;
    min-height: 7.1rem;
    margin: 0.55rem auto;
    .text {
      color: #7a7a7a;
      margin-bottom: 0.1rem;
      text-align: right;
      font-size: 0.18rem;
      .text-register {
        font-weight: 700;
        color: #3d67fa;
        font-size: 18px;
      }
    }
    .register-box {
      width: 4rem;

      padding: 0.3rem;
      background-color: #fff;
      border-radius: 0.04rem;
      .title {
        font-family: PingFang SC;
        margin-bottom: 0.4rem;
        color: #1e1e1e;
        font-size: 0.3rem;
        height: 0.22rem;
        line-height: 0.22rem;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        text-align: left;
      }
      .el-tabs {
        line-height: 40px;
        min-height: 40px;
        margin-bottom: 20px;
        text-align: left;
        /* .el-tab-pane {
        } */
        /deep/ .el-tabs__nav {
          .el-tabs__active-bar {
            width: 44px !important;
            height: 4px;
            background: #3d67fa;
            border-radius: 4px;
          }
          .el-tabs__item {
            font-weight: 700;
            font-family: PingFang SC;
            color: #7a7a7a;
          }

          .is-active {
            color: #1e1e1e;
          }
        }
        .el-tab-pane {
          .el-form {
            .btn-wrap {
              width: 100%;
              .el-form-item__content {
                display: flex;
                .el-input {
                  width: 70%;
                }
                .el-button {
                  height: 100%;
                  width: 27%;
                  margin-left: 10px;
                  margin-block: unset;
                }
              }
            }
            .last {
              margin-bottom: 10px;
            }
            .agree {
              margin-bottom: 30px;
              span {
                margin-left: 0.1rem;
                color: #e143ac;
                cursor: pointer;
              }
            }
            .el-button {
              margin-bottom: 20px;
              height: 46px;
              width: 100%;
              background-color: #3d67fa;
              text-align: center;
              font-weight: bolder;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
